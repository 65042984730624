import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDataContext } from "../contexts/AuthContext";
import { userLoginV3 } from "../services/api";
import waitingTermsStyles from '../styles/estilos/waitingTerms.module.css'
import logoPng from '../images/Frame.png'

function V3CustomerLogin() {
  const { accessCode, password } = useParams();
  const { setContextData } = useDataContext();
  const navigate = useNavigate();

  useEffect(() => {
    const clearTokenCookie = () => {
        document.cookie = "token=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
      };
  
      // Limpiar la cookie del token antes de iniciar sesión
    clearTokenCookie();

    const dni = decodeURIComponent(password);

    const datos = {
        "loginCode": accessCode,
        "dni": dni
    }
    userLoginV3(datos)
    .then((data) => {
      console.log("Respuesta del servidor:", data);
      setContextData(data.token);
      navigate("/terminos");
    })
    .catch((error) => {
      if (error.message === "401") {
        console.error("Error al enviar la solicitud:", error);
      } else {
        console.error("Error al enviar la solicitud:", error);
      }
    });
  }, [accessCode, password]);

  return (
    <div className={waitingTermsStyles.messageContainer}>
      <img src={logoPng} alt="littleBlueLogo" className={waitingTermsStyles.img}/>
      <h2 className='text-muted'>INGRESANDO A MCB</h2>
      <div className={waitingTermsStyles.loader}></div>
    </div>
  );
}

export default V3CustomerLogin;
