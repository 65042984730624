import React from "react";
import ReactDOM from "react-dom/client";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'
import "./index.css";
import App from "./App";
import { DataContextProvider } from "./contexts/AuthContext";
import { DebtsContextProvider } from "./contexts/FeeContext";
import { ExtraCostProvider } from "./contexts/extraCostContext";
import { LowerQuantityProvider } from "./contexts/LowerQuantityContext";


const getEnvironment = () => window.location.hostname === "localhost" ? "development" : window.location.hostname === "qa.micuenta.blue" ? "qa" : "production";

console.log("ENV VAR", process.env);

Bugsnag.start({
  apiKey: '46d6d2196a0de6b28b0597a8d56d5b08',
  releaseStage: getEnvironment(),
  plugins: [new BugsnagPluginReact()]
})
BugsnagPerformance.start({ apiKey: '46d6d2196a0de6b28b0597a8d56d5b08' })

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

window.testBugsnag = () => {
  Bugsnag.notify(new Error('Test error'))
};


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary>
    <React.StrictMode>
      <DataContextProvider>
        <DebtsContextProvider>
          <ExtraCostProvider>
            <LowerQuantityProvider>
          <App />
          </LowerQuantityProvider>
          </ExtraCostProvider>
        </DebtsContextProvider>
      </DataContextProvider>
    </React.StrictMode>
  </ErrorBoundary>
);
