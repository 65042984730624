import React, { useEffect, useState } from "react";
import { useDataContext } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "../styles/estilos/prendas.css";
import signoAtencion from "../images/signoatencion.png";
import frame from "../images/Frame.png";
import dashboardStyle from "../styles/Dashboard.module.css";
import Danger from "../images/Danger.png";
import {
  getItems,
  savePrendaExtra,
  getPaymentInformation,
} from "../services/api";
import Swal from "sweetalert2";
import logoPng from "../images/Frame.png";
import { useDebtsContext } from "../contexts/FeeContext";

const ModalExtraPrenda = ({ onNextClick, onSiguiente }) => {
  const navigate = useNavigate();
  const { contextData } = useDataContext();
  const [items, setItems] = useState([]);
  const [selectedQuantities, setSelectedQuantities] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const { setUserDebts } = useDebtsContext();

  useEffect(() => {
    const userKey = `hide-cloth-selection-modal-${contextData.customer.id}`;
    const storedData = localStorage.getItem(userKey);

    if (contextData.customer.customer_type !== 3) {
      // Si el usuario no es un tutor, mostrar el modal.
      setShowModal(true);
    } else if (storedData === null) {
      // Si es un tutor pero no hay datos en el localStorage, mostrar el modal.
      setShowModal(true);
    } else {
      const parsedData = JSON.parse(storedData);
      console.log("Datos parseados:", parsedData);
      // Si es un tutor y hay datos en el localStorage, seguir la lógica.
      const compraAceptada = storedData === "true";
      if (compraAceptada) {
        setShowModal(true);
      } else {
        navigate("/mcb/accountsSummary");
      }
    }
  }, [contextData.customer.id, contextData.customer.customer_type, navigate]);

  useEffect(() => {
    if (
      !contextData ||
      !contextData.customer ||
      !contextData.customer.lead_id
    ) {
      return;
    }

    const fetchItems = async () => {
      try {
        const data = await getItems(contextData.customer.lead_id);
        const initialSelectedItems = {};
        data.result.forEach((item, index) => {
          initialSelectedItems[index] = false;
          if (item.name === "Bandera") {
            initialSelectedItems[index] = false;
            setSelectedQuantities({ ...selectedQuantities, [index]: "1" });
          }
          setLoading(false);
        });
        setSelectedItems(initialSelectedItems);
        setItems(data.result);
      } catch (error) {
        console.log(`Error en la solicitud de items: ${error.message}`);
      }
    };

    fetchItems();
  }, [contextData.customer.lead_id]);

  // const handleQuantityChange = (index, event) => {
  //   const newQuantities = { ...selectedQuantities };
  //   newQuantities[index] = event.target.value;
  //   setSelectedQuantities(newQuantities);
  // };

  const handleQuantityChange = (index, event) => {
    const newQuantities = { ...selectedQuantities };
    newQuantities[index] = event.target.value;
    setSelectedQuantities(newQuantities);

    // Verifica si alguna cantidad seleccionada es distinta de 0
    const hasValidQuantity = Object.values(newQuantities).some(
      (qty) => parseInt(qty, 10) > 0
    );
    setIsNextButtonDisabled(!hasValidQuantity); // Si no hay cantidades válidas, deshabilita el botón
  };

  const handleCheckboxChange = (index) => {
    const newSelectedItems = { ...selectedItems };
    newSelectedItems[index] = !newSelectedItems[index];
    setSelectedItems(newSelectedItems);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const selectedItemsToSend = Object.keys(selectedQuantities)
      .filter((index) => selectedItems[index])
      .map((index) => ({
        customer_id: contextData.customer.id,
        order_item_id: items[index].id,
        quantity: parseInt(selectedQuantities[index], 10) || 0,
      }));

    const noItemsSelected = selectedItemsToSend.length === 0;

    const zeroQuantitySelected = selectedItemsToSend.some(
      (item) => item.quantity === 0
    );

    const visibleItems = items.filter((item) => item.name !== "Bandera");

    const noVisibleItemsSelected = selectedItemsToSend.every(
      (item) =>
        !visibleItems.some(
          (visibleItem) => visibleItem.id === item.order_item_id
        )
    );

    if (selectedItemsToSend.length === 0 || noVisibleItemsSelected) {
      Swal.fire({
        icon: "warning",
        iconColor: "#d54ab7",
        confirmButtonColor: "#0071ce",
        title: "Atencion",
        text: "Por favor selecciona la prenda y la cantidad que deseas de ella.",
      });
    } else if (zeroQuantitySelected) {
      Swal.fire({
        icon: signoAtencion,
        iconColor: "#d54ab7",
        confirmButtonColor: "#0071ce",
        title: "Atencion",
        text: "Por favor selecciona la cantidad que vas a querer en la prenda seleccionada.",
      });
    } else if (noItemsSelected) {
      Swal.fire({
        icon: "warning",
        iconColor: "#d54ab7",
        confirmButtonColor: "#0071ce",
        title: "Atencion",
        text: "Por favor selecciona una prenda y especificanos la cantidad que necesitas.",
      });
    } else {
      try {
        const response = await savePrendaExtra(
          contextData.customer.id,
          selectedItemsToSend
        );
        setUserDebts(response.allFees);
        console.log("Items extra guardados:", response);
      } catch (error) {
        console.error(`Error al guardar items: ${error.message}`);
        Swal.fire({
          icon: "error",
          iconColor: "#d54ab7",
          confirmButtonColor: "#0071ce",
          title: "Error",
          text: "Por favor, inténtelo de nuevo más tarde.",
        });
      }
    }

    onNextClick();
  };

  return (
    <>
      {loading ? ( // Mostrar indicador de carga mientras se verifica
        <div className="contcargando">
          <img src={logoPng} alt="littleBlueLogo" className="imgcargando" />
          <h2 className="text-muted">CARGANDO...</h2>
          <div className="loader"></div>
        </div>
      ) : (
        <>
          {showModal && (
            <div className={dashboardStyle.modalBackground}>
              <div className={dashboardStyle.modal}>
                <img
                  className={dashboardStyle.danger}
                  src={Danger}
                  alt="Danger"
                />
                <form onSubmit={handleSubmit}>
                  <div>
                    <img className="frame-image" src={frame} alt="frame" />
                  </div>
                  <div>
                    <div className="containerm1 mt-5 d-flex flex-column">
                      <h1 className="display-4 mb-4 titulo">
                        ¿Cuántas{" "}
                        <span style={{ color: "#d54ab7" }}>prendas </span>
                        deseas comprar?
                      </h1>
                      <h3>
                        Seleccione{" "}
                        <span style={{ color: "#d54ab7" }}>unicamente </span> la
                        prenda extra
                      </h3>
                    </div>
                    <div className="row justify-content-between">
                      {Array.isArray(items) &&
                        items.map((item, index) => {
                          const shouldHide = [
                            // "Bandera",
                            "Rifas (10 x pax)",
                            "Producto personalizado",
                          ].includes(item.name);

                          return !shouldHide ? (
                            // <div key={index} className="col-md-12 mb-4">
                            <div
                              key={index}
                              className={`col-md-12 mb-4 ${
                                item.name === "Bandera" ? "hidden" : ""
                              }`}
                            >
                              <div className="card">
                                <div className="card-body d-flex justify-content-between align-items-center">
                                  <div className="checkbox-container d-flex">
                                    <input
                                      type="checkbox"
                                      id={`checkbox-item-${index}`}
                                      className={`form-check-input ${
                                        selectedItems[index] ? " selected" : ""
                                      }`}
                                      checked={selectedItems[index] || false}
                                      onChange={() =>
                                        handleCheckboxChange(index)
                                      }
                                      disabled={item.order_item_status >= 300}
                                    />
                                    {item.order_item_status >= 300 ? (
                                      <div className="containerTit2">
                                        <h5 className="card-title ml-3 titulo2">
                                          {`${item.name} ${item.alias}(En producción)`}
                                        </h5>
                                        <p className="parrafoPrenda">
                                          No se pueden seleccionar prendas que
                                          esten en produccion
                                        </p>
                                      </div>
                                    ) : (
                                      <h5 className="card-title ml-3 titulo2">
                                        {item.name} {item.alias}
                                      </h5>
                                    )}
                                  </div>

                                  <div className="quantity-container">
                                    <label
                                      htmlFor={`checkbox-quantity-${index}`}
                                      className="quantity-label"
                                    >
                                      <select
                                        value={selectedQuantities[index] || "0"}
                                        onChange={(event) =>
                                          handleQuantityChange(index, event)
                                        }
                                        className="quantity-select checkbox"
                                        disabled={item.order_item_status >= 300}
                                      >
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                      </select>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null;
                        })}
                    </div>

                    <div className="d-flex justify-content-center align-items-center">
                      <button
                        className="btn btn-primary mr-3 buton"
                        onClick={onNextClick}
                      >
                        Cerrar
                      </button>
                      <button
                        disabled={isNextButtonDisabled}
                        // type="submit"
                        onClick={onSiguiente}
                        className="btn btn-primary mr-3 buton"
                      >
                        Siguiente
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ModalExtraPrenda;
