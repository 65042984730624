import Styles from "../styles/estilos/CondicionesonesRifas.module.css"
import { useNavigate } from 'react-router-dom';

const TerminosCondiciones = () => {

  const navigate = useNavigate();
  
  return (
    <div className={Styles.container}>
      <h2 className={Styles.title}>TÉRMINOS Y CONDICIONES DEL SORTEO SEMESTRAL ORGANIZADO Y GESTIONADO POR LITTLE BLUE S.R.L.</h2>
      <p>
      1. LITTLE BLUE S.R.L. (CUIT 30-71050128-5), con domicilio legal en Colectora Panamericana 1807, localidad de Villa Adelina, partido de San Isidro, Provincia de Buenos Aires, en adelante ‘la Empresa’, realiza esta promoción llamada “EGRESADOS” (la “Promoción”), que tendrá vigencia en la ámbito geográfico delimitado a continuación (el “Territorio”) y desde el 2 de julio de 2024a las 00:00 horas al 13 de diciembre de 2023 a las 12:00 horas, (la “Vigencia de laPromoción”), la cual quedará sujeta a las siguientes bases y condiciones (las“Bases”).
      </p>
      <p>
      1. Ámbito geográfico y vigencia: La Promoción sin obligación de compra denominada “EGRESADOS” (en adelante, el “Sorteo” y/o la “Promoción”),organizada por la empresa se llevará a cabo en la República Argentina, conexclusión de las provincia de Tierra del Fuego, Antártida e Islas del Atlántico Sury en el plazo de vigencia mencionado anteriormente.
      </p>
      <p>
      2. Podrán participar en la Promoción los interesados que reúnan y/o cumplan conlos siguientes requisitos: i) sean personas físicas mayores de 18 años de edad ycapaces, ii) residan en el Territorio y; iii) cumplan con la totalidad de los requisitosde participación establecidos en las presentes Bases (el/los “Participante/s”).
      </p>
      <p>
      3. No podrán participar en la Promoción las personas jurídicas, personas físicasmenores de 18 años ni domiciliadas fuera del Territorio, el personal ya seadependiente o externo de la Empresa y/o de las agencias involucradas en estaPromoción, y del personal por éstos contratado, sus cónyuges y/o parientes hasta elsegundo grado de consanguinidad y segundo de afinidad, ni los ex empleados quese hubieran desvinculado de las mismas dentro de los treinta (30) días corridosanteriores a la fecha del comienzo de la Promoción, ni sus cónyuges y/o parienteshasta el segundo grado de consanguinidad, inclusive.
      </p>
      <p>
      4. Mecánica de la Promoción: Podrán participar por los Premios que se indican enel punto 7.1. de las presentes Bases, todos los interesados que cumplan con la totalidad de los requisitos de la presente Promoción durante la Vigencia de la Promoción detallados a continuación:
      </p>
      <p>4.1- La Participación en el sorteo será realizado a través de un sistema de rifas lasque serán otorgadas de a diez números por la Empresa a cada egresado que haya adquirido indumentaria de egreso a través de LITTLE BLUE S.R.L., luego el cuálpodrá vender las rifas a quienes deseen participar de la promoción. <br />Cada rifa deberá darse de alta luego con los datos del participante en el portal digitalde la empresa www.littleblue.com.ar y se será solo a partir de ese momento considerado “participante” de la promoción.</p>
      <p>
      5. Cumpliendo con la mecánica de participación de la Promoción establecida en elPunto 4. precedente, el Participante participará por los Premios conforme loestablecido en las presentes Bases. Los Participantes podrán participar tantas vecescomo deseen a razón de una participación por cada rifa adquirida y dada de alta enwww.littleblue.com.ar durante la Vigencia de la Promoción.
      </p>
      <p>7. Premio:</p>
      <p>a) La entrega de (1) UN televisor TCL led de 32 pulgadas Android TV ,o similar, oen su defecto la suma de $250.000* <br />b) La entrega de (1) UN celular Motoroal G24 4gb ram + 128 gb de almacenamiento, o similar, o en su defecto la suma de $200.000 <br />c) Voucher de compra en oh my bat por $120.000 <br />En consecuencia, habrá TRES (3) Premios conforme las cantidades detalladas precedentemente <br />Un mismo participante no podrá ganar más de UNO (1) de los premios enumerados.En caso de haber sido sorteado ganador de uno de los premios, para el hipotéticocaso de haber sido sorteado ganador de otro, este último sorteo será descartado sinmás y prevalecerá el primer sorteo estando vedada la posibilidad de elección porparte del participante.</p>
      <p>
      7.2. El Premio no podrá ser transferido ni canjeado por otro tipo de premio equivalente por igual monto, y es para el uso personal del Ganador.
      </p>
      <p>
      8. Sorteo: El Sorteo del Premio indicado en el punto 7.1. se llevará a cabo el día 13de diciembre a las 16:00 horas en la calle Colectora panamericana 1807(el “Sorteo”), en el cual mediante la utilización de un programa de softwareoperado por el Organizador (“Software”), y ante escribano público, se elegirá alazar tres (3) Participantes quien, siempre que haya cumplido correctamente con losrequisitos de participación establecidos en las presentes Bases para la Promoción,será potencial ganador de los Premios enumerados en los incisos a), b) y c) delreferido punto 7.1 (el “Potencial Ganador del Premio”). Al momento del Sorteose labrará el acta notarial respectiva en la cual se procederá a individualizar alPotencial Ganador del Premio que resultó elegido al azar.
      </p>
      <p>
      9. En dicho Sorteo, se elegirá al azar dos (2) Participantes suplentes para cadapremio (el/los “Potencial/es Ganador/es Suplente/s”) para el caso que el Premiono resultare asignado al Potencial Ganador del Premio seleccionado en primertérmino.
      </p>
      <p>
      10. Es condición de notificación valida que los Datos ingresados en el Sitio y losde su Casilla de E-mail, a los efectos de la notificación del Potencial Ganador delPremio, sean completos y veraces y que la Casilla de E-mail, se encuentre habilitada, activa y accesible, bajo apercibimiento de dar por decaído el potencialderecho a la asignación del Premio.
      </p>
      <p>
      11. El Potencial Ganador será notificado por el Organizador de su condición de tal:(i) vía e-mail a su Casilla de E-mail o telefónicamente, a criterio del Organizador,(ii) vía redes sociales y/o (iii) en la Página Web, indicándole el procedimiento aseguir según el premio para el cual el participante haya sido asignado. 
      </p>
      <p>
      12. La asignación definitiva de cada Premio se encuentra supeditada alcumplimiento de todos los requisitos enumerados en las presentes condiciones, Esasimismo condición para la asignación del Premio que el Potencial Ganadorentregue una fotocopia en frente y dorso de su documento nacional de identidad. Elincumplimiento de cualquiera de estas condiciones hará perder al Potencial Ganador del Premio el derecho a la asignación del Premio, sin derecho a reclamoalguno
      </p>
      <p>
      13. A partir de la asignación definitiva del Premio, el Potencial Ganador del Premio resultará en ganador del Premio (el “Ganador”).
      </p>
      <p>
      15. El Premio será entregado al Ganador por la empresa según el premio que setrate, a saber:
      </p>
      <p>
      Para el caso de los incisos a) y b) del punto 7.1 a través de retiro personal delganador en las oficinas de la empresa denunciados en el encabezado de las presentes bases y/o por intermedio del sistema de envíos y encomiendas que el ganador elijapara envíos siendo ello responsabilidad única y excluyente del ganador, haciéndoseresponsable de cualquier evento dañoso que pudiere ocurrir con el Courier deenvios y eximiendo de toda responsabilidad a la Empresa en tanto la misma no tieneningún tipo de relación con el tercero elegido y/o designado por el ganador. <br /> Para el caso del inciso c) del punto 7.1 a través de transferencia bancaria a la cuentade titularidad exclusiva que el ganador designe e informe por los medios de contactoreferidos en el punto 11. <br />El plazo mínimo de entrega para todos los premios es de diez días y hasta veintedías hábiles contabilizados desde el día posterior a la fecha del sorteo.
      </p>
      <p>
      16. Sólo se entregará el Premio correspondiente al Participante que acredite suidentidad con el correspondiente documento nacional de identidad, y que acreditemediante declaración jurada ser el exclusivo ganador y titular del premio
      </p>
      <p>
      17. Finalizada la Vigencia de la Promoción, el Premio no asignado por cualquier causa, o habiendo sido asignado no hubiera sido reclamado, quedará en propiedad y a disposición del Organizador.
      </p>
      <p>
      18. El Premio no incluirá ningún otro pago, prestación, bien o servicio distinto deaquello indicado expresamente en las presentes Bases, siendo intransferible y elGanador no podrá cederlo. La sustitución del Premio por el Ganador está prohibida. El Premio no puede ser canjeado por dinero en efectivo ni por ningún otro bien nicualquier otra prestación distinta de las previstas en estas Bases. Todos los costosque se deriven de la participación o de la obtención o asignación y retiro del Premio,así como también todo impuesto, tasa, contribución, arancel o suma de dinero quedeba actualmente abonarse con motivo de la organización o difusión de laPromoción o del Sorteo al Estado Nacional, sociedades del estado y/o provinciasy/o municipalidades y/o Gobierno de la Ciudad de Buenos Aires o que se impongaen el futuro sobre el Premio o con motivo de la organización o difusión de laPromoción, como así también los gastos, sin limitación, de traslado y estadía en queincurra cuando concurra a reclamar la asignación o a retirar o a utilizar el Premio,como cualquier otro, estarán a exclusivo cargo del Ganador.
      </p>
      <p>
      19. El Organizador no será responsable por los daños y perjuicios que pudiera sufrirel Potencial Ganador, el Ganador, sus acompañantes o terceros, en sus personas obienes, con motivo o en ocasión de la participación en la Promoción y/o con motivoo en ocasión del retiro y/o asignación del Premio.
      </p>
      <p>
      20. El nombre completo del Ganador será publicado en el Sitio Web de la Empresay/o en sus redes sociales para lo cual el ganador presta su expresa autorización paraque ello suceda sin que ello implique lugar a resarcimientos por parte de la Empresaal Ganador.
      </p>
      <p>
      21. El Organizador podrá ampliar la cantidad de premios y/o la Vigencia de laPromoción, ello conforme la normativa vigente. Cuando circunstancias noimputables al Organizador ni previstas en estas Bases o que constituyan casofortuito o fuerza mayor lo justifiquen, el Organizador podrá suspender, cancelar omodificar esta Promoción, supuesto en el cual los Participantes no tendrán derechoa reclamo alguno, siempre conforme la normativa vigente. Toda modificación quese realice a estas Bases será sin alterar la esencia de la Promoción, respetandose la normativa vigente. La participación en la Promoción no implica obligación decompra alguna.
      </p>
      <p>
      22. La probabilidad de ser Ganador de alguno del Premio dependerá de la cantidadtotal de participaciones adquiridas por los participantes; la presente promoción
      ofrece 36.000 (TREINTA Y SEIS MIL) participaciones. En ese caso y a modo deejemplo, el participante que adquiera 1 (una) participación tendrá 3 (tres) chancesen 36.000 (TREINTA Y SEIS MIL) de ganar algun premio.
      </p>
      <p>
      23. El Potencial Ganador como condición para tener derecho a la asignación alPremio, además de lo expresado en los puntos anteriores, autorizará al Organizadora utilizar sus voces, imágenes y datos personales, con fines promocionales, en losmedios y formas que el Organizador disponga, durante la Vigencia de la Promocióny hasta transcurrido un (1) año de finalizada la Promoción. La presente autorizaciónes realizada en los términos del artículo 53 y concordantes del Código Civil yComercial de la Nación, del artículo 11 del Pacto de San José de Costa Rica, delartículo 31 de la Ley N° 11.723, del art. 3, inc. h) de la Ley N° 22.362, de losartículos 5 y 6 de la Ley 25.326 y toda otra normativa concordante. En virtud deesta autorización, el Potencial Ganador y/o el Ganador no tendrá derecho a indemnización, pago o contraprestación alguna por la difusión y/o utilización de suimagen mencionada precedentemente.
      </p>
      <p>
      24. El Organizador no será responsable por fallas en la red Internet, desperfectos técnicos, errores humanos o acciones deliberadas de terceros que pudieran interrumpir o alterar el normal desarrollo de la Promoción.  
      </p>
      <p>
      25. La participación en la Promoción implica la aceptación de estas Bases y de lasdecisiones que adopte el Organizador, conforme a derecho, sobre cualquier cuestiónno prevista en ellas. El Organizador tendrá derecho a definir, establecer e interpretar aquellas situaciones o circunstancias que no estén expresamente previstas en laspresentes Bases, sin afectar la esencia de la Promoción, decisiones que seráninapelables y los Participantes no tendrán derecho a reclamo alguno, aclarándose que cualquiera de las mencionadas circunstancias, modificaciones y decisiones serán ajustadas a la legislación vigente.
      </p>
      <p>
      29. Estas Bases podrán consultarse en el Sitio <a href="https://www.littleblue.com.ar" target="_blank">www.littleblue.com.ar</a>.
      </p>
      <p>
      30. La presente Promoción será difundida el Sitio <a href="https://www.littleblue.com.ar" target="_blank">www.littleblue.com.ar</a>.
      </p>
      <p>
      31. Para cualquier controversia o cuestión judicial que pudiera derivarse de la realización de la Promoción, el Organizador y los Participantes, por el sólo hecho de participar en la Promoción, se someten a la jurisdicción de los Tribunales Ordinarios del departamento judicial de San Isidro.
      </p>
      <div className={Styles.containerBoton}>
        <button onClick={() => navigate(-1)}  className={Styles.boton}>Atras</button>
      </div>
    </div>
  );
}

export default TerminosCondiciones;