import styles from "../styles/ConfirmarItems.module.css";
import dashboardStyle from "../styles/Dashboard.module.css";
import Danger from "../images/Danger.png";
import { getPaymentInformation } from "../services/api";
import logoPng from "../images/Frame.png";
import React, { useEffect, useState } from "react";
import { useDataContext } from "../contexts/AuthContext";
import frame from "../images/Frame.png";



export default function ModalConfirmarItems({
  handleAceptar,
  handleCancelar,
  userDebtsWithStyles,
}) {
  const { contextData } = useDataContext();
  const [paymentMethod, setPaymentMethod] = useState("");
  const [items, setItems] = useState([]);
  const [paymentCount, setPaymentCount] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getPaymentInformation(contextData.customer.id)
      .then((data) => {
        console.log("Datos de pago:", data);
        setPaymentMethod(data.payment_method);
        setItems(data.items);
        setPaymentCount(data.paymentCount);
        setLoading(false);
        console.log(data.items);
        console.log(data.payment_method);
      })
      .catch((error) => {
        console.log("Error en la solicitud de información de pago", error);
      });
  }, [contextData.customer.id]);

  const totalCuotas = userDebtsWithStyles.length;
  return (
    <>
      {loading ? (
        <div className="contcargando">
          <img src={logoPng} alt="littleBlueLogo" className="imgcargando" />
          <h2 className="text-muted">CARGANDO...</h2>
          <div className="loader"></div>
        </div>
      ) : (
        <div className={dashboardStyle.modalBackground}>
          <div className={dashboardStyle.modal}>
            <img className={dashboardStyle.danger} src={Danger} alt="Danger" />
            <div>
            <img className="frame-image" src={frame} alt="frame" />
          </div>
            <div className={styles.container}>
              <div>
                <h1 className={styles.titulo}>
                  Prendas y <span style={{ color: "#d54ab7" }}>método </span> de
                  pago seleccionado
                </h1>
                <div>
                  <h3 className={styles.subtitulo1}>Prendas seleccionadas</h3>
                  <div className={styles.containerPrendas}>
                    {items.map((item, index) => (
                      <div key={index} className={styles.item}>
                        <h5 className={styles.nombreitem}>{item.name} {item.alias}</h5>
                        <h4 className={styles.cantidad}>Cantidad: {item.quantity}</h4>
                        {/* <p>Precio: {item.price}</p> */}
                      </div>
                    ))}
                  </div>
                </div>
                <div>
                  <h3 className={styles.subtitulo2}>Método de pago</h3>
                  <div className={styles.containerMetodoPago}>
                    <h5 className={styles.metodoPago}>{paymentMethod}</h5>
                    <h4 className={styles.cantidad}>Total de cuotas: {totalCuotas}</h4>
                  </div>
                </div>
                <div className={styles.containerBotones}>
                  <button
                    className={styles.btnconfirmar}
                    onClick={handleAceptar}
                  >
                    Confirmar
                  </button>
                  <button
                    className={styles.btncancelar}
                    onClick={handleCancelar}
                  >
                     Modificar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
