import React, { useEffect, useState } from "react";
import { useDataContext } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";
import "../styles/estilos/prendas.css";
import signoAtencion from "../images/signoatencion.png";
import frame from "../images/Frame.png";
import dashboardStyle from "../styles/Dashboard.module.css";
import Danger from "../images/Danger.png";
import { getItems, saveItems, getPaymentInformation } from "../services/api";
import Swal from "sweetalert2";
import logoPng from "../images/Frame.png";

const Modal1 = ({
  onNextClick,
  closeModal,
  setPaymentMethod,
  vengodelModal1,
  paymentMethod,
  vengodelModalPagos,
}) => {
  const navigate = useNavigate();
  const { contextData } = useDataContext();
  const [items, setItems] = useState([]);
  const [selectedQuantities, setSelectedQuantities] = useState({});
  const [selectedItems, setSelectedItems] = useState({});
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isCloseButtonEnabled, setIsCloseButtonEnabled] = useState(false);
  const [storedData, setStoredData] = useState(null);

  useEffect(() => {
    const userKey = `hide-cloth-selection-modal-${contextData.customer.id}`;
    const storedDataValue = localStorage.getItem(userKey);

    setStoredData(storedDataValue);

    if (contextData.customer.customer_type !== 3) {
      setShowModal(true);
    } else if (storedDataValue === null) {
      setShowModal(true);
    } else {
      const compraAceptada = storedDataValue === "true";
      if (compraAceptada) {
        setShowModal(true);
      } else {
        navigate("/mcb/accountsSummary");
      }
    }
  }, [contextData.customer.id, contextData.customer.customer_type, navigate]);

  useEffect(() => {
    if (!contextData || !contextData.customer || !contextData.customer.id) {
      return;
    }
    vengodelModal1(true);
    localStorage.setItem("vengoDelModal1", "true");
    getPaymentInformation(contextData.customer.id)
      .then((data) => {
        console.log("Datos de pago:", data);
        setPaymentMethod(data.payment_method);
        // Habilitar el botón de cierre si payment_method tiene un valor
        if (data.payment_method) {
          setIsCloseButtonEnabled(true);
        } else {
          setIsCloseButtonEnabled(false);
        }
      })
      .catch((error) => {
        console.error("Error al obtener la información de pago:", error);
      });
  }, [contextData.customer.id]);

  useEffect(() => {
    if (
      !contextData ||
      !contextData.customer ||
      !contextData.customer.lead_id
    ) {
      return;
    }

    const fetchItems = async () => {
      try {
        const data = await getItems(contextData.customer.lead_id);
        console.log("Datos de items obtenidos:", data);
        const initialSelectedItems = {};
        data.result.forEach((item, index) => {
          initialSelectedItems[index] = false;
          if (item.name === "Bandera") {
            initialSelectedItems[index] = true;
            setSelectedQuantities({ ...selectedQuantities, [index]: "1" });
          }
          setLoading(false);
        });
        setSelectedItems(initialSelectedItems);
        setItems(data.result);
      } catch (error) {
        console.log(`Error en la solicitud de items: ${error.message}`);
      }
    };

    fetchItems();
  }, [contextData.customer.lead_id]);

  const handleQuantityChange = (index, event) => {
    const newQuantities = { ...selectedQuantities };
    newQuantities[index] = event.target.value;
    setSelectedQuantities(newQuantities);
  };

  const handleCheckboxChange = (index) => {
    const newSelectedItems = { ...selectedItems };
    newSelectedItems[index] = !newSelectedItems[index];
    setSelectedItems(newSelectedItems);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const selectedItemsToSend = Object.keys(selectedQuantities)
      .filter((index) => selectedItems[index])
      .map((index) => ({
        customer_id: contextData.customer.id,
        order_item_id: items[index].id,
        quantity: parseInt(selectedQuantities[index], 10) || 0,
      }));

    console.log("selectedItemsToSend:", selectedItemsToSend);

    const noItemsSelected = selectedItemsToSend.length === 0;

    const zeroQuantitySelected = selectedItemsToSend.some(
      (item) => item.quantity === 0
    );

    const visibleItems = items.filter((item) => item.name !== "Bandera");

    const noVisibleItemsSelected = selectedItemsToSend.every(
      (item) =>
        !visibleItems.some(
          (visibleItem) => visibleItem.id === item.order_item_id
        )
    );

    if (selectedItemsToSend.length === 0 || noVisibleItemsSelected) {
      Swal.fire({
        icon: "warning",
        iconColor: "#d54ab7",
        confirmButtonColor: "#0071ce",
        title: "Atencion",
        text: "Por favor selecciona la prenda y la cantidad que deseas de ella.",
      });
    } else if (zeroQuantitySelected) {
      Swal.fire({
        icon: signoAtencion,
        iconColor: "#d54ab7",
        confirmButtonColor: "#0071ce",
        title: "Atencion",
        text: "Por favor selecciona la cantidad que vas a querer en la prenda seleccionada.",
      });
    } else if (noItemsSelected) {
      Swal.fire({
        icon: "warning",
        iconColor: "#d54ab7",
        confirmButtonColor: "#0071ce",
        title: "Atencion",
        text: "Por favor selecciona una prenda y especificanos la cantidad que necesitas.",
      });
    } else {
      if (paymentMethod) {
        // Si hay un método de pago, mostrar la alerta para volver a seleccionarlo
        Swal.fire({
          icon: "warning",
          iconColor: "#d54ab7",
          text: "Deberas seleccionar nuevamente el metodo de pago",
          confirmButtonColor: "#0071ce",
          showCancelButton: true,
          cancelButtonColor: "#d54ab7",
          confirmButtonText: "Aceptar",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.isConfirmed) {
            try {
              const response = saveItems(
                contextData.customer.id,
                selectedItemsToSend
              );
              console.log("Items guardados:", response);
            } catch (error) {
              console.error(`Error al guardar items: ${error.message}`);
              Swal.fire({
                icon: "error",
                iconColor: "#d54ab7",
                confirmButtonColor: "#0071ce",
                title: "Error",
                text: "Por favor, inténtelo de nuevo más tarde.",
              });
            }
            onNextClick();
          }
        });
      } else {
        // Si no hay método de pago, continuar sin mostrar alerta
        try {
          const response = await saveItems(
            contextData.customer.id,
            selectedItemsToSend
          );
          console.log("Items guardados:", response);
        } catch (error) {
          console.error(`Error al guardar items: ${error.message}`);
          Swal.fire({
            icon: "error",
            iconColor: "#d54ab7",
            confirmButtonColor: "#0071ce",
            title: "Error",
            text: "Por favor, inténtelo de nuevo más tarde.",
          });
        }
        onNextClick();
      }
    }
  };

  return (
    <>
      {loading ? ( // Mostrar indicador de carga mientras se verifica
        <div className="contcargando">
          <img src={logoPng} alt="littleBlueLogo" className="imgcargando" />
          <h2 className="text-muted">CARGANDO...</h2>
          <div className="loader"></div>
        </div>
      ) : (
        <>
          {showModal && (
            <div className={dashboardStyle.modalBackground}>
              <div className={dashboardStyle.modal}>
                <img
                  className={dashboardStyle.danger}
                  src={Danger}
                  alt="Danger"
                />
                <form onSubmit={handleSubmit}>
                  <div>
                    <img className="frame-image" src={frame} alt="frame" />
                  </div>
                  <div>
                    <div className="containerm1 mt-5 d-flex flex-column">
                      <h1 className="display-4 mb-4 titulo">
                        ¿Cuántas{" "}
                        <span style={{ color: "#d54ab7" }}>prendas </span>
                        deseas comprar?
                      </h1>
                      <h6 className="text-muted mb-4">
                        En caso que no quieras un producto, no lo selecciones.
                      </h6>
                    </div>
                    <div className="row justify-content-between">
                      {Array.isArray(items) &&
                        items.map((item, index) => {
                          const shouldHide = [
                            // "Bandera",
                            "Rifas (10 x pax)",
                            "Producto personalizado",
                          ].includes(item.name);

                          return !shouldHide ? (
                            // <div key={index} className="col-md-12 mb-4">
                            <div
                              key={index}
                              className={`col-md-12 mb-4 ${
                                item.name === "Bandera" ? "hidden" : ""
                              }`}
                            >
                              <div className="card">
                                <div className="card-body d-flex justify-content-between align-items-center">
                                  <div className="checkbox-container">
                                    <input
                                      type="checkbox"
                                      id={`checkbox-item-${index}`}
                                      className={`form-check-input ${
                                        selectedItems[index] ? " selected" : ""
                                      }`}
                                      checked={selectedItems[index] || false}
                                      onChange={() =>
                                        handleCheckboxChange(index)
                                      }
                                    />
                                    <h5 className="card-title ml-3 titulo2">
                                      {item.name} {item.alias}
                                    </h5>
                                  </div>
                                  <div className="quantity-container">
                                    <label
                                      htmlFor={`checkbox-quantity-${index}`}
                                      className="quantity-label"
                                    >
                                      <select
                                        value={selectedQuantities[index] || "0"}
                                        onChange={(event) =>
                                          handleQuantityChange(index, event)
                                        }
                                        className="quantity-select checkbox"
                                      >
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                      </select>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null;
                        })}
                    </div>

                    <div className="d-flex justify-content-center align-items-center">
                      {/* <button
                        className="btn btn-primary mr-3 buton"
                        disabled={!isCloseButtonEnabled}
                        onClick={closeModal}
                      >
                        Cerrar
                      </button> */}
                      {paymentMethod && (
                        <button
                          className="btn btn-primary mr-3 buton"
                          disabled={!isCloseButtonEnabled || vengodelModalPagos}
                          onClick={closeModal}
                        >
                          Cerrar
                        </button>
                      )}
                      {contextData.customer.customer_type === 3 &&
                        !paymentMethod && (
                          <Link
                            to="/mcb/accountsSummary"
                            className="btn btn-primary mr-3 buton"
                          >
                            Cerrar
                          </Link>
                        )}
                      <button
                        type="submit"
                        className="btn btn-primary mr-3 buton"
                      >
                        Siguiente
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Modal1;
