import React, { useState, useEffect } from "react";
import Estilos from "../styles/CargarTalles.module.css";
import confirmar from "../styles/ConfirmarTalle.module.css";
import danger from "../images/Danger.png";
import fantasmita2 from "../images/fantasmita2.png";
import arcoiris2 from "../images/arcoiris2.png";
import x from "../images/x.png";
import catalogoEmojis from "../images/catalogoemojis.png";
import prendasStyle from "../styles/Prendas.module.css";
import { DataContext, useDataContext } from "../contexts/AuthContext";
import Swal from "sweetalert2";
import {
  fetchAllUsers,
  prendasCadaUsuario,
  getPaymentInformation,
} from "../services/api";
import { uploadSize, getSizes, getUserSizes } from "../services/api";
import AlertPropio from "../components/AlertPropio";
import { Navigate, useNavigate } from "react-router-dom";
import GuiaTallesJardinModal from "../components/GuiaTallesJardinModal";
import GuiaTallesSecundariaModal from "../components/GuiaTallesSecundariaModal.jsx";
import GuiaTallesOversizeModal from "../components/GuiaTallesOversizeModal";

const Talles = ({ orderItemId, onClose, prenda, prendasConPath }) => {
  const navigate = useNavigate();
  const [alumno, setAlumno] = useState("");
  const [selectedAlumno, setSelectedAlumno] = useState("");
  const [apodo, setApodo] = useState("");
  const [personalizado, setPersonalizado] = useState("");
  const [emojiOpen, setEmojiOpen] = useState(false);
  const [jardinVisible, setJardinVisible] = useState(false);
  const [jardinSizes, setJardinSizes] = useState(Array(11).fill(false));
  const [oversize, setOversize] = useState(false);
  const [oversizeSizes, setOversizeSizes] = useState(Array(9).fill(false));
  const [secundariaVisible, setSecundariaVisible] = useState(false);
  const [secundariaSizes, setSecundariaSizes] = useState(Array(12).fill(false));
  const [allUsers, setAllUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedSizeType, setSelectedSizeType] = useState("");
  const [newSize, setNewSize] = useState("");
  const { contextData } = useDataContext();
  const [isConfirming, setIsConfirming] = useState(false);
  const { customer } = contextData;
  const customer_id = customer.id;
  const productName = contextData.product_name;
  const [items, setItems] = useState([]);
  const [tallesCargados, setTallesCargados] = useState({});
  const [disableEnviar, setDisableEnviar] = useState(false);
  const [tallesCargadosAlumno, setTallesCargadosAlumno] = useState({});
  const [countCustomerOrderItem, setCountCustomerOrderItem] = useState(0);
  const [countOrderItemPaxes, setCountOrderItemPaxes] = useState(0);

  console.log("lo que trae prendaconpath:", prendasConPath);

  useEffect(() => {
    if (!customer || !customer.customer_type || !customer.first_name) {
      return;
    }
    if (customer.customer_type === 2 || customer.customer_type === 3) {
      prendasCadaUsuario(contextData.customer.lead_id, orderItemId)
        .then((users) => {
          setAllUsers(users);
          if (users.length > 0) {
            setSelectedAlumno(users[0].id); // Establecer el primer usuario como seleccionado por defecto
            setAlumno(users[0].first_name + " " + users[0].last_name);
          }
          console.log(users);
        })
        .catch((error) => console.error("Error al obtener usuarios:", error));
    } else {
      setAllUsers([]);
      setAlumno(customer.first_name + " " + customer.last_name || "");
    }
  }, [customer.customer_type, customer.first_name]);

  const handleAlumnoChange = (e) => {
    setAlumno(e.target.value);
  };

  const handleSelectedAlumnoChange = async (e) => {
    const selectedUserId = parseInt(e.target.value); // Convertir a número entero
    setSelectedAlumno(selectedUserId);

    // Buscar el usuario seleccionado en la lista de usuarios
    const selectedUser = allUsers.find((user) => user.id === selectedUserId);

    if (selectedUser) {
      setAlumno(selectedUser.first_name + " " + selectedUser.last_name);

      // Obtener talles cargados del alumno seleccionado
      try {
        const data = await getUserSizes(selectedUserId, orderItemId); // Obtener los tamaños cargados para order_item_id específico
        const userTallesCargados = data.filter(
          (item) =>
            item.customer_id === selectedUserId &&
            item.order_item_id === orderItemId
        );

        // Contar cuántos talles ha cargado el alumno seleccionado para la prenda actual
        const countTallesCargadosAlumno = userTallesCargados.length;

        // Actualizar el estado de tallesCargadosAlumno
        setTallesCargadosAlumno({
          ...tallesCargadosAlumno,
          [prenda]: countTallesCargadosAlumno,
        });
      } catch (error) {
        console.error("Error al obtener talles cargados:", error);
      }
    } else {
      setAlumno("");
      console.log(
        "Usuario no encontrado. Nombre del alumno actualizado a vacío."
      );
    }
  };

  const uploadSizes = async (e) => {
    e.preventDefault();
    setIsConfirming(true);

    // Verificar la disponibilidad de customer_id
    if (!customer_id) {
      console.error("customer_id no está disponible");
      return;
    }

    console.log("orderItemId:", orderItemId);

    const selectedSizes = jardinVisible
      ? jardinSizes
      : oversize
      ? oversizeSizes
      : secundariaSizes;

    const newSizeIndex = selectedSizes.findIndex((size) => size);
    let newSize = "";
    if (newSizeIndex >= 0) {
      if (jardinVisible) {
        newSize = `p${newSizeIndex * 2 + 4}`;
      } else if (oversize) {
        newSize = ["2XS","3XS","4XS","XS", "S", "M", "L", "XL", "XXL"][newSizeIndex];
      } else if (secundariaVisible) {
        newSize =
          newSizeIndex === secundariaSizes.length - 1
            ? "12"
            : `${newSizeIndex}`;
      }
    }

    setNewSize(newSize);
    if (newSize === "") {
      Swal.fire({
        icon: "warning",
        iconColor: "#d54ab7",
        confirmButtonColor: "#0071ce",
        title: "Atención",
        text: "Debes seleccionar un talle.",
      });
      return;
    } else if (alumno === "") {
      Swal.fire({
        icon: "warning",
        iconColor: "#d54ab7",
        confirmButtonColor: "#0071ce",
        title: "Atención",
        text: "Debes seleccionar un alumno.",
      });
      return;
    }

    if (
      contextData.customer.customer_type === 2 ||
      contextData.customer.customer_type === 3
    ) {
      let data = {
        name: alumno,
        nickname: apodo,
        initials: personalizado,
        comments: null,
        gender: "f",
        width: 0.0,
        height: 0.0,
        size: newSize,
        order_item_id: orderItemId,
        created_by_id: customer_id,
        customer_id: selectedAlumno,
      };
      console.log("Datos a enviar:", data);
      console.log("Customer ID:", customer_id);

      try {
        const jsonResponse = await uploadSize(data);
        console.log("Talle creado exitosamente:", jsonResponse);
        actualizarTallesCargados(prenda); // Actualiza el contador de talles cargados
        navigate("/AlertPropio");
      } catch (error) {
        console.error("Error al crear el talle:", error.message);
        Swal.fire({
          icon: "error",
          title: "Error al cargar talles",
          text: "Hubo un problema al cargar los talles. Por favor, intenta de nuevo más tarde.",
        });
      } finally {
        setIsConfirming(false);
      }
    } else {
      const data = {
        name: alumno,
        nickname: apodo,
        initials: personalizado,
        comments: null,
        gender: "f",
        width: 0.0,
        height: 0.0,
        size: newSize,
        order_item_id: orderItemId,
        created_by_id: customer_id,
        customer_id: customer_id,
      };
      console.log("Datos a enviar:", data);
      console.log("Customer ID:", customer_id);

      try {
        const jsonResponse = await uploadSize(data);
        console.log("Talle creado exitosamente:", jsonResponse);
        actualizarTallesCargados(prenda); // Actualiza el contador de talles cargados
        navigate("/AlertPropio");
      } catch (error) {
        console.error("Error al crear el talle:", error.message);
        Swal.fire({
          icon: "error",
          title: "Error al cargar talles",
          text: "Hubo un problema al cargar los talles. Por favor, intenta de nuevo más tarde.",
        });
      } finally {
        setIsConfirming(false);
      }
    }
  };

  const handleApodoChange = (e) => {
    setApodo(e.target.value);
  };

  const handlePersonalizadoChange = (e) => {
    setPersonalizado(e.target.value);
  };

  const toggleEmojiMenu = () => {
    setEmojiOpen(!emojiOpen);
  };

  const togglePrimaria = () => {
    setJardinVisible(!jardinVisible);
    setSelectedSizeType("Jardin/Primaria");
  };

  const toggleOversize = () => {
    setOversize(!oversize);
    setSelectedSizeType("Oversize");
  };

  const toggleSecundaria = () => {
    setSecundariaVisible(!secundariaVisible);
    setSelectedSizeType("Secundaria");
  };
  const [showguiaTallesJardinModal, setShowguiaTallesJardinModal] =
    useState(false);
  const handleVerGuiaTallesJardin = () => {
    setShowguiaTallesJardinModal(true);
  };
  const handleCloseGuiaTallesjardin = () => {
    setShowguiaTallesJardinModal(false);
  };
  const [showGuiaTallesSecundariaModal, setShowGuiaTallesSecundariaModal] =
    useState(false);
  const handleShowGuiaTallesSecundariaModal = () => {
    setShowGuiaTallesSecundariaModal(true);
  };
  const handleCloseGuiaTallesSecundariaModal = () => {
    setShowGuiaTallesSecundariaModal(false);
  };
  const [showGuiaTallesOversizeModal, setShowGuiaTallesOversizeModal] =
    useState(false);
  const handleShowGuiaTallesOversizeModal = () => {
    setShowGuiaTallesOversizeModal(true);
  };
  const handleCloseGuiaTallesOversizeModal = () => {
    setShowGuiaTallesOversizeModal(false);
  };
  const handleCheckboxChange = (index) => {
    setJardinSizes((prevSizes) => {
      const updatedJardinSizes = prevSizes.map((size, i) =>
        i === index ? !size : false
      );
      const newSizeIndex = updatedJardinSizes.findIndex((size) => size);
      const newSize = newSizeIndex >= 0 ? `P${newSizeIndex * 2 + 4}` : "";
      setNewSize(newSize);
      setOversizeSizes(Array(9).fill(false));
      setSecundariaSizes(Array(12).fill(false));
      return updatedJardinSizes;
    });
  };

  const handleOversizeCheckboxChange = (index) => {
    setOversizeSizes((prevSizes) => {
      const updatedOversizeSizes = prevSizes.map((size, i) =>
        i === index ? !size : false
      );
      const newSizeIndex = updatedOversizeSizes.findIndex((size) => size);
      const newSize =
        newSizeIndex >= 0
          ? ["2XS","3XS","4XS","XS", "S", "M", "L", "XL", "XXL"][newSizeIndex]
          : "";
      setNewSize(newSize);
      setJardinSizes(Array(11).fill(false));
      setSecundariaSizes(Array(12).fill(false));
      return updatedOversizeSizes;
    });
  };

  const handleSecundariaCheckboxChange = (index) => {
    setSecundariaSizes((prevSizes) => {
      const updatedSecundariaSizes = prevSizes.map((size, i) =>
        i === index ? !size : false
      );

      let newSize = "";
      if (index === 11) {
        newSize = "12";
      } else {
        // Obtener el nuevo tamaño indexado según la selección
        const newSizeIndex = updatedSecundariaSizes.findIndex((size) => size);
        newSize = newSizeIndex >= 0 ? `${newSizeIndex}` : "";
      }

      // Actualizar el estado del nuevo tamaño
      setNewSize(newSize);

      setJardinSizes(Array(11).fill(false));
      setOversizeSizes(Array(9).fill(false));

      return updatedSecundariaSizes;
    });
  };

  const handleEnviarClick = (e) => {
    e.preventDefault();

    if (newSize === "") {
      Swal.fire({
        icon: "warning",
        iconColor: "#d54ab7",
        confirmButtonColor: "#0071ce",
        title: "Atención",
        text: "Debes seleccionar un talle.",
      });
    } else if (alumno === "") {
      Swal.fire({
        icon: "warning",
        iconColor: "#d54ab7",
        confirmButtonColor: "#0071ce",
        title: "Atención",
        text: "Debes seleccionar un alumno.",
      });
    } else {
      setShowModal(true);
      if (newSize === "11") {
        setNewSize("12");
      }
    }
  };

  useEffect(() => {
    if (contextData.customer.customer_type === 2 || contextData.customer.customer_type === 3) {
      // Lógica para tutores
      const cantidadTotal = countCustomerOrderItem;
      const cantidadCargada = countOrderItemPaxes;
      setDisableEnviar(cantidadCargada >= cantidadTotal);
    } else {
      // Lógica para usuarios regulares
      const cantidadTotal =
        items.find((item) => item.name === prenda)?.quantity || 0;
      const cantidadCargada = tallesCargados[prenda] || 0;
      setDisableEnviar(cantidadCargada >= cantidadTotal);
    }
  }, [tallesCargados, prenda, items, countCustomerOrderItem, countOrderItemPaxes, contextData.customer.customer_type]);
  

  const handleCancelarEnviar = () => {
    setShowModal(false);
  };

  const actualizarTallesCargados = (nombreItem) => {
    setTallesCargados((prev) => ({
      ...prev,
      [nombreItem]: (prev[nombreItem] || 0) + 1,
    }));
  };

  useEffect(() => {
    const fetchTallesCargados = async () => {
      try {
        let data;
        if (
          contextData.customer.customer_type === 2 ||
          contextData.customer.customer_type === 3
        ) {
          // Si es tutor, obtener los talles cargados para el alumno seleccionado
          console.log(
            "Obteniendo talles cargados para el alumno:",
            selectedAlumno
          );
          data = await getUserSizes(selectedAlumno, orderItemId);
          console.log("Respuesta de getUserSizes:", data);

          // Actualizar los estados con countCustomerOrderItem y countOrderItemPaxes
          if (data.length > 0) {
            setCountCustomerOrderItem(data[0].countCustomerOrderItem || 0);
            setCountOrderItemPaxes(data[0].countOrderItemPaxes || 0);
          }
        } else {
          // Si no es tutor, obtener los talles cargados para el usuario logueado
          console.log(
            "Obteniendo talles cargados para el usuario logueado:",
            contextData.customer.id
          );
          data = await getSizes(orderItemId);
          console.log("Respuesta de getSizes:", data);
        }

        // Filtrar los talles cargados por el usuario logueado o alumno seleccionado y para el order_item_id específico
        const currentUserTalles = data.filter(
          (item) =>
            item.customer_id ===
              (contextData.customer.customer_type === 2 ||
              contextData.customer.customer_type === 3
                ? selectedAlumno
                : contextData.customer.id) && item.order_item_id === orderItemId
        );

        // Contar cuántos talles ha cargado el usuario logueado o alumno seleccionado para la prenda actual
        const countTallesCargados = currentUserTalles.length;

        // Actualizar el estado de tallesCargados
        setTallesCargados({
          ...tallesCargados,
          [prenda]: countTallesCargados,
        });
      } catch (error) {
        console.error("Error al obtener talles cargados:", error);
      }
    };

    fetchTallesCargados();
  }, [
    orderItemId,
    prenda,
    contextData.customer.id,
    selectedAlumno,
    contextData.customer.customer_type,
  ]);

  useEffect(() => {
    getPaymentInformation(contextData.customer.id)
      .then((data) => {
        setItems(data.items);
        console.log("este es data item", data.items);
        console.log(`Prenda seleccionada: ${prenda} `);
      })
      .catch((error) => {
        console.log("Error en la solicitud de información de pago", error);
      });
  }, [contextData.customer.id]);

  return (
    <>
      <div className={prendasStyle.modalBackground}>
        <div className={prendasStyle.modalC}>
          <div className={`container mt-5 ${Estilos.containerprincipal}`}>
            <div className="d-flex">
              <button onClick={onClose} className={Estilos.cruzcerrar}>
                <img className={Estilos.imagcruz} src={x} alt="" />
              </button>
            </div>
            <div>
              <h2 className={`mb-4 ${Estilos.titulo}`}>Cargar Talles</h2>

              <div className={prendasStyle.containerPrendas}>
                {prendasConPath
                  .filter((prendaItem) => prendaItem.product_name === prenda)
                  .map((prendaItem, index) => {
                    const cantidadTotal =
                      items.find((item) => item.name === prenda)?.quantity || 0;
                    const cantidadCargada = tallesCargados[prenda] || 0;

                    return (
                      <div key={index} className={Estilos.contitem}>
                        <h4 className={Estilos.nombreitem}>
                          {prendaItem.product_name} {prendaItem.alias}
                        </h4>
                        {contextData.customer.customer_type === 2 ||
                        contextData.customer.customer_type === 3 ? (
                          <>
                            <h4 className={Estilos.cantidad}>
                              Cantidad seleccionada: {countCustomerOrderItem}
                            </h4>
                            <h4 className={Estilos.cantidad}>
                              Cantidad de talles cargados: {countOrderItemPaxes}
                            </h4>
                          </>
                        ) : (
                          <>
                            <h4 className={Estilos.cantidad}>
                              Cantidad: {cantidadTotal}
                            </h4>
                            <h4 className={Estilos.cantidad}>
                              Cargados: {cantidadCargada}/{cantidadTotal}
                            </h4>
                          </>
                        )}
                      </div>
                    );
                  })}
              </div>

              <p className={Estilos.subtitulo}>
                Completa los siguientes talles:
              </p>
            </div>
            <form className={Estilos.containerform}>
              {customer.customer_type === 2 || customer.customer_type === 3 ? (
                <div className={Estilos.containerinputs}>
                  <label
                    htmlFor="selectedAlumno"
                    className={Estilos.labealumno}
                  >
                    {" "}
                    Alumno:
                  </label>
                  <div className="selectContainer">
                    <select
                      className={Estilos.flechaselect}
                      id="selectedAlumno"
                      name="selectedAlumno"
                      value={selectedAlumno}
                      onChange={handleSelectedAlumnoChange}
                    >
                      {allUsers &&
                        allUsers.map((user) => (
                          <option key={user.id} value={user.id}>
                            {" "}
                            {user.first_name}
                            {user.last_name}
                          </option>
                        ))}
                    </select>{" "}
                  </div>
                </div>
              ) : (
                <div className={` ${Estilos.containerinputs}`}>
                  <label htmlFor="alumno" className={`mr-2 ${Estilos.labels}`}>
                    Alumno
                  </label>
                  <input
                    name="name"
                    type="text"
                    className={`form-control ${Estilos.inputs}`}
                    id="alumno"
                    value={alumno}
                    onChange={handleAlumnoChange}
                  />
                </div>
              )}

              <div className={` ${Estilos.containerinputs}`}>
                <label htmlFor="apodo" className={`mr-2 ${Estilos.labels}`}>
                  Apodo
                </label>
                <input
                  name="nickname"
                  type="text"
                  className={`form-control ${Estilos.inputsapodo}`}
                  id="apodo"
                  value={apodo}
                  onChange={handleApodoChange}
                />
              </div>
              <div className="d-flex">
                <p className={Estilos.mensajeemoji}>
                  El emoji solo puede ir ADELANTE Y/O ATRAS del apodo, NO EN
                  MEDIO.
                </p>
                <div>
                  <img src={danger} className={Estilos.danger} alt="" />
                </div>
              </div>
              <div>
                <h5 className={Estilos.tips}>Tips a tener en cuenta:</h5>
                <div className="d-flex">
                  <div>
                    <img
                      src={x}
                      alt="Icono de información"
                      className={Estilos.x}
                    />
                  </div>
                  <p className={`mb-4 ${Estilos.apodos}`}>
                    Si no quieres que lleve apodo, deja este campo vacío
                  </p>
                  <div>
                    <img
                      src={x}
                      alt="Icono de información"
                      className={Estilos.xx}
                    />
                  </div>
                </div>
                <div className="d-flex">
                  <div>
                    <img
                      src={fantasmita2}
                      alt="Icono de información"
                      className={Estilos.fantasma}
                    />
                  </div>
                  <p className={`mb-5 text-center ${Estilos.apododos}`}>
                    Los emojis pueden llegar a tener un costo extra,
                    <br /> consultalo con tu asesor/a{" "}
                  </p>
                  <div>
                    <img
                      src={arcoiris2}
                      alt="Icono de información"
                      className={Estilos.arcoiriss}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex mb-4">
                <h3 className={Estilos.catalogo}>Catalogo de emojis</h3>
                <button
                  type="button"
                  className={`btn  ${Estilos.emojiboton}`}
                  onClick={toggleEmojiMenu}
                >
                  {emojiOpen ? (
                    <i className="bi bi-caret-up-fill"></i>
                  ) : (
                    <i className="bi bi-caret-down-fill"></i>
                  )}
                </button>
              </div>
              <div>
                {emojiOpen && (
                  <div>
                    <img
                      src={catalogoEmojis}
                      alt="Icono de información"
                      className={Estilos.emojis}
                    />
                  </div>
                )}
              </div>
              <div className={` ${Estilos.containerinputs}`}>
                <label
                  htmlFor="personalizado"
                  className={`mr-2 ${Estilos.labels}`}
                >
                  Personalizado
                </label>
                <input
                  name="comments"
                  type="text"
                  className={`form-control ${Estilos.inputs}`}
                  id="personalizado"
                  value={personalizado}
                  onChange={handlePersonalizadoChange}
                />
              </div>
              <div>
                <p className={`form-text text-muted ${Estilos.parrafo}`}>
                  En caso que tu prenda lleve algo mas personalizado aclaralo en
                  este campo.
                  <br />
                  Ej modalidad, opcionales, numeros personalizados.
                </p>
              </div>
              <div>
                <h3 className={Estilos.subtitulo}> TALLES</h3>
                <div className={Estilos.contaionermt}>
                  <p className={Estilos.mensajeTalles}>
                    {`Si pediste 2 o mas unidades de ${prenda}, carga el talle para cada uno`}

                    <img src={danger} className={Estilos.danger2} alt="" />
                  </p>
                </div>
              </div>

              <div className="d-flex mb-4">
                <p className={Estilos.titulotalle}>Jardin/Primaria</p>
                <button
                  type="button"
                  className={`btn  ${Estilos.tallesboton}`}
                  onClick={togglePrimaria}
                >
                  {jardinVisible ? (
                    <i className="bi bi-caret-up-fill"></i>
                  ) : (
                    <i className="bi bi-caret-down-fill"></i>
                  )}
                </button>
              </div>
              {jardinVisible && (
                <div>
                  <div className={`d-flex ${Estilos.checkboxContainer}`}>
                    {jardinSizes.map((checked, index) => (
                      <label
                        key={index}
                        className={`btn ${Estilos.checkboxLabel} ${
                          checked ? Estilos.checkboxChecked : ""
                        }`}
                      >
                        <input
                          name="size"
                          type="checkbox"
                          className={`btn-check ${Estilos.numero}`}
                          checked={checked}
                          onChange={() => handleCheckboxChange(index)}
                        />
                        <span className={Estilos.numero}>P{index * 2 + 4}</span>
                      </label>
                    ))}
                  </div>
                  <button
                    type="button"
                    className={`btn btn-primary ${Estilos.botonguiadetalles}`}
                    onClick={handleVerGuiaTallesJardin}
                  >
                    Ver guía de talles
                  </button>

                  {/* Modal de la guía de talles */}
                  {showguiaTallesJardinModal && (
                    <GuiaTallesJardinModal
                      onClose={handleCloseGuiaTallesjardin}
                    />
                  )}
                </div>
              )}

              <div className="d-flex mb-4">
                <p className={Estilos.titulotalle}>Oversize</p>
                <button
                  type="button"
                  className={`btn  ${Estilos.tallesboton}`}
                  onClick={toggleOversize}
                >
                  {oversize ? (
                    <i className="bi bi-caret-up-fill"></i>
                  ) : (
                    <i className="bi bi-caret-down-fill"></i>
                  )}
                </button>
              </div>
              <div>
                {oversize && (
                  <div>
                    <div className={`d-flex ${Estilos.checkboxContainer}`}>
                      {["2XS","3XS","4XS","XS", "S", "M", "L", "XL", "XXL"].map((size, index) => (
                        <label
                          key={index}
                          className={`btn ${Estilos.checkboxLabel} ${
                            oversizeSizes[index] ? Estilos.checkboxChecked : ""
                          }`}
                        >
                          <input
                            name="size"
                            type="checkbox"
                            className={`btn-check ${Estilos.numero}`}
                            checked={oversizeSizes[index]}
                            onChange={() => handleOversizeCheckboxChange(index)}
                          />
                          {size}
                        </label>
                      ))}
                    </div>
                    <button
                      type="button"
                      className={`btn btn-primary ${Estilos.botonguiadetalles}`}
                      onClick={handleShowGuiaTallesOversizeModal}
                    >
                      Ver guía de talles
                    </button>
                    {showGuiaTallesOversizeModal && (
                      <GuiaTallesOversizeModal
                        onClose={handleCloseGuiaTallesOversizeModal}
                      />
                    )}
                  </div>
                )}
              </div>
              <div className="d-flex mb-4">
                <p className={Estilos.titulotalle}>Secundaria</p>
                <button
                  type="button"
                  className={`btn  ${Estilos.tallesboton}`}
                  onClick={toggleSecundaria}
                >
                  {secundariaVisible ? (
                    <i className="bi bi-caret-up-fill"></i>
                  ) : (
                    <i className="bi bi-caret-down-fill"></i>
                  )}
                </button>
              </div>
              <div>
                {secundariaVisible && (
                  <div>
                    <div className={`d-flex ${Estilos.checkboxContainer}`}>
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((size) => (
                        <label
                          key={size}
                          className={`btn ${Estilos.checkboxLabel} ${
                            secundariaSizes[size] ? Estilos.checkboxChecked : ""
                          }`}
                        >
                          <input
                            name="size"
                            type="checkbox"
                            className="btn-check"
                            checked={secundariaSizes[size]}
                            onChange={() =>
                              handleSecundariaCheckboxChange(size)
                            }
                          />
                          {size === 11 ? "12" : size}
                        </label>
                      ))}
                    </div>
                    <button
                      type="button"
                      className={`btn btn-primary ${Estilos.botonguiadetalles}`}
                      onClick={handleShowGuiaTallesSecundariaModal}
                    >
                      Ver guía de talles
                    </button>
                    {showGuiaTallesSecundariaModal && (
                      <GuiaTallesSecundariaModal
                        onClose={handleCloseGuiaTallesSecundariaModal}
                      />
                    )}
                  </div>
                )}
              </div>
              <div className={Estilos.containerbotonenviar}>
                <button className={Estilos.botonatras} onClick={onClose}>
                  ATRAS
                </button>

                <button
                  className={`btn btn-primary ${Estilos.botonenviar}`}
                  onClick={handleEnviarClick}
                  disabled={disableEnviar}
                  // onClick={uploadSizes}
                >
                  Enviar
                </button>
              </div>
            </form>
            {showModal && (
              <div
                className="modal"
                tabIndex="-1"
                role="dialog"
                style={{
                  display: "block",
                  overflowX: "visible",
                  overflowY: "visible",
                }}
              >
                <div className="modal-dialog" role="document">
                  <div className={`modal-content ${confirmar.containermodal}`}>
                    <div>
                      <button
                        onClick={handleCancelarEnviar}
                        className={Estilos.cruzcerrar2}
                      >
                        <img className={Estilos.imagcruz2} src={x} alt="" />
                      </button>
                    </div>
                    <div className="modal-header">
                      <h3 className={confirmar.titulo}>
                        Confirmas que los datos ingresados son correctos?
                      </h3>
                    </div>
                    <div className="modal-body">
                      <p className={confirmar.titulosselect}>
                        Alumno:{" "}
                        <span className={confirmar.alumnoselect}>
                          {" "}
                          {alumno}
                        </span>
                      </p>
                      <p className={confirmar.titulosselect}>
                        Apodo:{" "}
                        <span className={apodo ? confirmar.apodoselect : ""}>
                          {apodo}
                        </span>
                      </p>
                      <p className={confirmar.titulosselect}>
                        Personalizado:{" "}
                        <span
                          className={
                            personalizado ? confirmar.personalizadoselect : ""
                          }
                        >
                          {" "}
                          {personalizado}{" "}
                        </span>
                      </p>
                      <p className={confirmar.tittalle}>TAlle</p>
                      <p className={confirmar.titulosselect}>
                        {" "}
                        {selectedSizeType}{" "}
                        <span className={confirmar.talleselect}>{newSize}</span>
                      </p>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className={`btn btn-secondary ${confirmar.botoncancelar}`}
                        onClick={handleCancelarEnviar}
                      >
                        Cancelar
                      </button>
                      {/* <button
                        type="button"
                        className={`btn ${confirmar.botonconfirmar}`}
                        onClick={uploadSizes}
                      >
                        Confirmar
                      </button> */}
                      <button
                        type="button"
                        className={`btn ${confirmar.botonconfirmar}`}
                        onClick={uploadSizes}
                        disabled={isConfirming}
                      >
                        {isConfirming ? "Confirmando..." : "Confirmar"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Talles;
