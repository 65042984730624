import rifasStyle from "../styles/estilos/rifas.module.css";
import React, { useEffect, useState } from "react";
import danger from "../images/Danger.png";
import { validarRifa, fillRaffleData, getAllPrizes } from "../services/api";
import Swal from "sweetalert2";
import defaultPrize1 from '../images/default_prize_1.png';
import defaultPrize2 from '../images/default_prize_2.png';
import defaultPrize3 from '../images/default_prize_3.png';
import { Link } from "react-router-dom";

const Rifas = () => {
  const currentyear = new Date().getFullYear();
  const [codigo, setCodigo] = useState("");
  const [number, setNumber] = useState("");
  const [nombre, setNombre] = useState("");
  const [dni, setDni] = useState("");
  const [fecha, setFecha] = useState("");
  const [telefono, setTelefono] = useState("");
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModa2] = useState(false);
  const [errorMensaje, setErrorMensaje] = useState("");
  const [isValidating, setIsValidating] = useState(false);
  const [nombreError, setNombreError] = useState("");
  const [dniError, setDniError] = useState("");
  const [fechaError, setFechaError] = useState("");
  const [telefonoError, setTelefonoError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [prizes, setPrizes] = useState([]);

  useEffect(()=> {
    const getAllPrizeImages = async () => {
      try {
        const response = await getAllPrizes();
        const prizes = response;
        setPrizes(prizes);
      } catch (error) {
        console.error("Ha ocurrido un error al tratar de traer las imagenes de los premios!");
      }
    }

    getAllPrizeImages();
  }, []);

  const handleCodigoChange = (event) => {
    const value = event.target.value.toUpperCase();
    setCodigo(value);
  };
  const handleNumberChange = (event) => {
    const value = event.target.value.replace(/\D/g, "");
    setNumber(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const codigoValido = /^[A-Z0-9]+$/.test(codigo);
    if (!codigoValido) {
      setErrorMensaje(
        "El código debe contener solo letras mayúsculas y números."
      );
      return;
    }

    const numeroValido = /^\d+$/.test(number);
    if (!numeroValido) {
      setErrorMensaje("El número debe contener solo números.");
      return;
    }

    setIsValidating(true);
    setErrorMensaje("");

    try {
      await validarRifa(codigo, number);

      console.log("Validación exitosa, abriendo modal...");
      setShowModal(true);
    } catch (error) {
      console.log("Error en el catch:", error.message);
      setErrorMensaje(error.message);
    } finally {
      setIsValidating(false);
    }
  };

  const handleModal2 = (event) => {
    event.preventDefault();
    setShowModa2(true);
  };

  const handleGuardar = async (event) => {
    event.preventDefault();

    let isValid = true;

    // Validación de Nombre
    if (!nombre) {
      setNombreError("El nombre es obligatorio.");
      isValid = false;
    } else if (!/^[A-Za-z\s]+$/.test(nombre)) {
      setNombreError("El nombre solo debe contener letras y espacios.");
      isValid = false;
    } else {
      setNombreError("");
    }

    // Validación de DNI
    if (!dni) {
      setDniError("El DNI es obligatorio.");
      isValid = false;
    } else if (!/^\d{8}$/.test(dni)) {
      setDniError("El DNI debe ser un número de 8 dígitos.");
      isValid = false;
    } else {
      setDniError("");
    }
    // Validación de Teléfono
    if (!telefono) {
      setTelefonoError("El teléfono es obligatorio.");
      isValid = false;
    } else if (!/^\d+$/.test(telefono)) {
      setTelefonoError("El teléfono debe contener solo números.");
      isValid = false;
    } else {
      setTelefonoError("");
    }

    // Validación de Email
    if (!email) {
      setEmailError("El correo electrónico es obligatorio.");
      isValid = false;
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      setEmailError("El formato del correo electrónico no es válido.");
      isValid = false;
    } else {
      setEmailError("");
    }

    // Validación de Fecha de Nacimiento
    const regexFecha = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
    if (!fecha) {
      setFechaError("La fecha de nacimiento es obligatoria.");
      isValid = false;
    } else if (!regexFecha.test(fecha)) {
      setFechaError("Formato inválido. Usa DD/MM/AAAA.");
      isValid = false;
    } else {
      setFechaError("");
    }

    if (isValid) {
      const year = fecha.split("/")[2];

      const bodyData = {
        name: nombre,
        dni,
        birthday: year,
        phone: telefono,
        email
      };

      try {
        const result = await fillRaffleData(codigo, number, bodyData);
        handleCloseModal();
        Swal.fire({
          icon: "success",
          iconColor: "#d54ab7",
          confirmButtonColor: "#0071ce",
          title: "Exito!",
          text: `${result.message}`,
        });
        console.log("Datos guardados correctamente:", result);
      } catch (error) {
        console.log("Error al guardar los datos:", error.message);
        handleCloseModal();
        Swal.fire({
          icon: "warning",
          iconColor: "#d54ab7",
          confirmButtonColor: "#0071ce",
          title: "Ha ocurrido un error!",
          text: `${error.message}`,
        });
      }
    }
  };

  const handleCloseModal = () => {
    setNombre("");
    setDni("");
    setFecha("");
    setTelefono("");
    setEmail("");

    setShowModal(false);
  };

  return (
    <>
      <h1 className={rifasStyle.title}>RIFAS {currentyear}</h1>
      <form onSubmit={handleSubmit} className={rifasStyle.form}>
        <section className={rifasStyle.inputcontainer}>
          <div className={rifasStyle.one}>
            <label className={rifasStyle.labelPrincipal} htmlFor="codigo">
              Código
            </label>
            <input
              className={rifasStyle.input}
              placeholder="Por ejemplo: X21D70"
              type="text"
              id="codigo"
              value={codigo}
              onChange={handleCodigoChange}
              required
            />
            <p className={rifasStyle.parrafo}>
              Ingresá los 6 caractéres alfanuméricos.
            </p>
          </div>
          <div className={rifasStyle.one}>
            <label className={rifasStyle.labelPrincipal} htmlFor="number">
              Número
            </label>
            <input
              className={rifasStyle.input}
              placeholder="Por ejemplo: 04123"
              type="number"
              id="number"
              value={number}
              onChange={handleNumberChange}
              required
            />
            <p className={rifasStyle.parrafo}>Ingresá el número de tu rifa.</p>
          </div>
        </section>
        <div className={rifasStyle.containerBotones}>
          <div>
            <button className={rifasStyle.button} onClick={handleModal2}>
              Información
            </button>
          </div>
          <div>
            <button
              className={rifasStyle.button}
              type="submit"
              disabled={isValidating}
            >
              {isValidating ? "Enviando..." : "Enviar"}
            </button>
          </div>
        </div>
      </form>
      <div className={rifasStyle.mensajeError}>
        {errorMensaje && <p>{errorMensaje}</p>}
      </div>

      {/* modal */}

      {showModal && (
        <div
          className="modal show"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog" role="document">
            <div className={`modal-content ${rifasStyle.containerPrincipal}`}>
              <div className={rifasStyle.cruzCerrar}>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleCloseModal}
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div>
                    <h5 className={`modal-title ${rifasStyle.subtitulo}`}>
                      Completa con los datos del participante
                    </h5>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="nombre"
                      className={rifasStyle.labelParticipante}
                    >
                      NOMBRE PARTICIPANTE:
                    </label>
                    <input
                      type="text"
                      className={`form-control ${rifasStyle.inputs}`}
                      id="nombre"
                      value={nombre}
                      onChange={(e) => setNombre(e.target.value)}
                      required
                      pattern="[A-Za-z\s]+"
                      title="Solo letras y espacios"
                    />
                    {nombreError && (
                      <div className="text-danger">{nombreError}</div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="dni"
                      className={rifasStyle.labelParticipante}
                    >
                      DNI DEL PARTICIPANTE:
                    </label>
                    <input
                      type="text"
                      className={`form-control ${rifasStyle.inputs}`}
                      id="dni"
                      value={dni}
                      onChange={(e) =>
                        setDni(e.target.value.replace(/\D/g, "").slice(0, 8))
                      }
                      required
                      pattern="\d{8}"
                      title="Debe contener 8 números"
                    />
                    {dniError && <div className="text-danger">{dniError}</div>}
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="fecha"
                      className={rifasStyle.labelParticipante}
                    >
                      FECHA DE NACIMIENTO:
                    </label>
                    <input
                      type="text"
                      className={`form-control ${rifasStyle.inputs}`}
                      id="fecha"
                      value={fecha}
                      onChange={(e) => setFecha(e.target.value)}
                      required
                      placeholder="DD/MM/AA"
                      title="Formato válido: dd/mm/aa"
                    />
                    {fechaError && (
                      <div className="text-danger">{fechaError}</div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="telefono"
                      className={rifasStyle.labelParticipante}
                    >
                      TELÉFONO:
                    </label>
                    <input
                      type="text"
                      className={`form-control ${rifasStyle.inputs}`}
                      id="telefono"
                      value={telefono}
                      onChange={(e) =>
                        setTelefono(e.target.value.replace(/\D/g, ""))
                      }
                      required
                      title="Debe contener solo números"
                    />
                    {telefonoError && (
                      <div className="text-danger">{telefonoError}</div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="email"
                      className={rifasStyle.labelParticipante}
                    >
                      EMAIL:
                    </label>
                    <input
                      type="email"
                      className={`form-control ${rifasStyle.inputs}`}
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      placeholder="ejemplo@correo.com"
                      title="Formato válido de correo electrónico"
                    />
                    {emailError && (
                      <div className="text-danger">{emailError}</div>
                    )}
                  </div>
                </form>
              </div>
              <div className={rifasStyle.containerBotonmodal}>
                <button
                  type="submit"
                  className={`btn ${rifasStyle.botonValidarModal}`}
                  onClick={handleGuardar}
                >
                  GUARDAR
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {showModal2 && (
        <div
          className="modal show"
          tabIndex="-1"
          role="dialog"
          style={{ display: "block" }}
        >
          <div className="modal-dialog" role="document">
            <div className={`modal-content ${rifasStyle.containerPrincipal}`}>
              <div className={rifasStyle.cruzCerrar}>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModa2(false)}
                ></button>
              </div>
              <div className={rifasStyle.containericono}>
                <img className={rifasStyle.imageDanger} src={danger} alt="" />
                <p className={rifasStyle.tituloinfo}>
                  {" "}
                  IMPORTANTE: <br /> Validar las rifas de lo contrario no forman
                  parte del sorteo.
                </p>
              </div>
              <div>
                <h6 className={rifasStyle.subtituloinfo}>
                  ¿CÓMO OBTENGO MIS RIFAS?
                </h6>
                <p className={rifasStyle.parrafosInfo}>
                  Las rifas podés solicitarlas a tu asesor/a de Atención al
                  cliente y las van <br /> a gestionar de manera online. Les
                  vamos a enviar un talonario para que <br /> puedan llevar
                  llevar tambien ustedes un control de las rifas que venden,{" "}
                  <br /> pero van a tener que cargarlas en nuestra plataforma.
                </p>
                <div>
                  <h6 className={rifasStyle.subtituloinfo}>
                    ¿CUÁNTO SALE CADA RIFA?
                  </h6>
                  <p className={rifasStyle.parrafosInfo}>
                    El valor de la rifa le ponen ustedes, pueden ponerse de
                    acuerdo entre el <br />
                    grupo o hacerlo de forma individual.
                  </p>
                </div>
                <div>
                  <h6 className={rifasStyle.subtituloinfo}>
                    ¿CUÁNTAS RIFAS TENGO?
                  </h6>
                  <p className={rifasStyle.parrafosInfo}>
                    Cada alumno cuenta con 10 rifas.
                  </p>
                </div>
                <div>
                  <h6 className={rifasStyle.subtituloinfo}>
                    ¿CÚANDO SE SORTEA?
                  </h6>
                  <p className={rifasStyle.parrafosInfo}>
                    El sorteo se realiza el 01/07/24 a las 16 hs.
                  </p>
                </div>
                <div>
                  <h6 className={rifasStyle.subtituloinfo}>
                    ¿POR DONDE SE SORTEA?
                  </h6>
                  <p className={rifasStyle.parrafosInfo}>
                    Van a poder ver los resultados a través de nuestras redes
                    sociales.
                  </p>
                </div>
                <div>
                  <h6 className={rifasStyle.subtituloinfo}>
                    ¿HASTA CUANDO PUEDO VALIDAR MIS RIFAS?
                  </h6>
                  <p className={rifasStyle.parrafosInfo}>
                    Podes validar tus rifas hasta un dia antes del sorteo.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

        <section className="premios-container mt-5">
          {prizes.length > 0 ? (
            <div className="row d-flex justify-content-around">
              {prizes
                .sort((a, b) => a.prize_number - b.prize_number)
                .slice(0, 3)
                .map((prize, index) => (
                  <div 
                    key={prize.prize_number} 
                    className={`premios col-12 col-md-4 d-flex flex-column text-center ${index === 0 ? 'order-md-2' : index === 1 ? 'order-md-1' : 'order-md-3'}`}
                  >
                    <p className="premios2">{`PREMIO ${prize.prize_number}`}</p>
                    <img 
                      src={
                        prize.image_url || 
                        (prize.prize_number === 1 ? defaultPrize1 :
                        prize.prize_number === 2 ? defaultPrize2 :
                        defaultPrize3)
                      } 
                      alt={`Premio ${prize.prize_number}`} 
                      // className="mx-auto"
                    />
                  </div>
                ))}
            </div>
          ) : (
            <div className="row d-flex justify-content-around">
              <div className="premios col-12 col-md-4 d-flex flex-column text-center order-md-1">
                <p className={rifasStyle.premios2}>PREMIO 2</p>
                <img src={defaultPrize2} alt="Premio 2" className="w-50 mx-auto"/>
              </div>
              <div className="premios col-12 col-md-4 d-flex flex-column text-center order-md-2">
                <p className={rifasStyle.premios2}>PREMIO 1</p>
                <img src={defaultPrize1} alt="Premio 1" className="w-50 mx-auto"/>
              </div>
              <div className="premios col-12 col-md-4 d-flex flex-column text-center order-md-3">
                <p className={rifasStyle.premios2}>PREMIO 3</p>
                <img src={defaultPrize3} alt="Premio 3" className="w-50 mx-auto"/>
              </div>
            </div>
          )}
        </section>

      <div className={rifasStyle.terminos}>
        {/* <a href="../components/Terminos_Condiciones/TerminosCondiciones">{">"}Bases y Condiciones Rifas</a> */}
        <Link to="/TerminosCondiciones"> Términos y Condiciones </Link>,
      </div>
    </>
  );
};
export default Rifas;
